.cardItem {
    margin: auto;
    width: 50%;
    max-width: 800px;
    display: inline-block;
}

@media (max-width: 960px) and (min-width: 501px) {
    .cardItem {
        width: 50%;
    }
}

@media (max-width: 500px) {
    .cardItem {
        width: 100%;
    }
}