.fruitCard {
    min-width: 300px;
    display: contents;
}

.fruitCard > div {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    justify-content: stretch;
    align-items: stretch;
}

.fruitCard > div.animated {
    transition: transform .2s;
    transform: scale(1);
    animation: zoom 1s forwards;
}

.fruitCard .fruitCard-photo {
    flex: 1;
}

.fruitCard > div.animated .fruitCard-photo {
    transform: scale(0);
    animation: zoom 1s forwards;
    animation-delay: 500ms;
}

.fruitCard .fruitCard-name {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fruitCard .fruitCard-photo img {
    max-width: 300px;
    width: 100%;
    height: auto;
}

.fruitCard .fruitCard-info {
    display: block;
    flex: 1;
    background-color: #f3f6f5;
    padding: 8px;
}

.fruitCard .fruitCard-info .fruitCard-info-name {
    font-family: 'Quintessential', cursive;
    font-size: 12px;
}

.fruitCard .fruitCard-info .fruitCard-info-details {
    padding: 8px;
    font-size: 24px;
}

@keyframes zoom {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

@media (min-width: 961px) {
    .fruitCard .fruitCard-info .fruitCard-info-name {
        font-size: 3vw;
    }
}

@media (max-width: 960px) and (min-width: 501px) {
    .fruitCard .fruitCard-info .fruitCard-info-name {
        font-size: 3vw;
    }

    .fruitCard .fruitCard-info .fruitCard-info-details {
        font-size: 2vw;
    }
}

@media (max-width: 500px) {
    .fruitCard .fruitCard-info .fruitCard-info-name {
        font-size: 32px
    }
}