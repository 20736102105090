.card {
    box-shadow: 0.05rem 0.1rem 0.3rem -0.03rem rgba(0, 0, 0, 0.45);
    padding: 5px;
    margin: 5px;
    width: 100%;
    background-color: white;
    overflow: hidden;
    border-radius: 3px 0 3px 0;
}

.card:hover {
    box-shadow: 0.4rem 0.4rem 0.4rem -0.03rem rgba(0, 0, 0, 0.30);
    cursor: pointer;
}